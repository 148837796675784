export const journeyData = [
  {
    icon: "tradingItemOne",
    title: "Pickup Your Couriers",
    description:
      "Conveniently schedule and manage the pickup of your packages from your location. Whether you're at home or at the office, our service ensures that your couriers are collected on time, saving you the hassle of drop-offs and letting you focus on what matters.",
  },
  {
    icon: "tradingItemTwo",
    title: "Ship Your Couriers",
    description:
      "Seamlessly send your packages to any destination with our reliable shipping service. From local to international deliveries, we ensure that your couriers reach their destination safely and on time, with easy tracking and full transparency throughout the process.",
  },
  {
    icon: "tradingItemThree",
    title: "Express Delivery",
    description:
      "Get your packages delivered faster with our express delivery service. Ideal for urgent shipments, we prioritize speed and efficiency, ensuring that your items arrive at their destination in the shortest possible time, without compromising on security or care.",
  },
  // {
  //   icon: "tradingItemFour",
  //   title: "Real-Time Updates",
  //   description:
  //     "Stay updated with live scores, player performances, and make informed decisions to stay ahead in the game.",
  // },
  // {
  //   icon: "tradingItemFive",
  //   title: "User-Friendly Interface",
  //   description:
  //     "Enjoy a seamless, intuitive experience designed for both beginners and experts, making navigation effortless.",
  // },
  // {
  //   icon: "tradingItemSix",
  //   title: "Secure and Reliable",
  //   description:
  //     "Play with confidence, knowing your data and transactions are securely protected, ensuring a trustworthy environment.",
  // },
];

export const chooseData = [
  {
    icon: "ProductItemone",
    title: "Seamless Integration",
    description:
      "Experience the ease of integrating JustPayze into your platform. Our seamless integration process ensures that you can start accepting payments without any hassle. Say goodbye to complex setups and hello to a smoother payment experience for your customers.",
  },
  {
    icon: "ProductItemtwo",
    title: "Dashboard with Real-Time Insights",
    description:
      "Monitor your transactions and gain valuable insights with our feature-rich dashboard. Access real-time big data outputs that you the information needed to make informed decisions. Stay ahead of the curve and optimize your payment processes effortlessly.",
  },
  {
    icon: "ProductItemthree",
    title: "User-Friendly Checkout Pages",
    description:
      "Enhance your customers' experience with our user-friendly checkout pages. We understand the importance of a smooth and intuitive payment process. With JustPayze, you can create a seamless checkout journey that fosters customer satisfaction and loyalty.",
  },
  {
    icon: "ProductItemfour",
    title: "Highest Security Measures at Every Step",
    description:
      "Security is our top priority. Rest easy knowing that JustPayze employs the highest security measures at every step of the payment process. From data encryption to fraud prevention,we've got you covered, ensuring a secure environment for both you and your customers.",
  },
];

export const whyChooseData = [
  {
    icon: "whyChoose1",
    title: "Reliable and On-Time Deliveries",
    description:
      "Count on us for prompt and timely deliveries, with real-time tracking to ensure your packages arrive without delay.",
  },
  {
    icon: "whyChoose2",
    title: "Comprehensive Coverage",
    description:
      "From local to remote locations, our wide network ensures your packages reach their destination, no matter where",
  },
  {
    icon: "whyChoose3",
    title: "Customer-Centric Service",
    description:
      "We prioritize your satisfaction with easy booking, responsive support, and a focus on meeting your needs.",
  },
];

export const accordionFullData = [
  {
    id: "flush-headingOne",
    title: "1. What areas do you service?",
    content:
      "Zippyglide.com offers nationwide courier services across the country. We also provide international shipping to many destinations around the globe. Please contact our customer support to confirm service availability in your specific area.",
  },
  {
    id: "flush-headingTwo",
    title: "2. How can I track my shipment?",
    content:
      "Once your shipment is dispatched, you will receive a tracking number via email or SMS. You can use this tracking number on our [Track Your Order] (link to tracking page) page to get real-time updates on the status and location of your package.",
  },
  {
    id: "flush-headingThree",
    title: "3. What are your delivery timeframes?",
    content: `Delivery timeframes vary based on the service selected and the destination. For domestic deliveries, we offer same-day, next-day, and standard 3-5 business days options. International delivery times depend on the destination country and chosen service. Please refer to our [Services](link to services page) page for more details.`,
  },
  {
    id: "flush-headingFour",
    title: "4. What should I do if my package is delayed or lost?",
    content: `If your package is delayed or you believe it is lost, please contact our customer support team immediately with your tracking number. We will investigate the issue and keep you informed throughout the process. You can reach us at support@zippyglide.com or +123-456-7890.`,
  },
  {
    id: "flush-headingFive",
    title: "5. How do I arrange a pickup for my shipment?",
    content: `Arranging a pickup with Zippyglide.com is easy. You can schedule a pickup through our website by logging into your account and selecting the 'Schedule a Pickup' option. Alternatively, you can contact our customer support team to arrange a pickup at your convenience.
`,
  },
];

export const clientReview = [
  {
    icon: "startPlay2",
    title: "Dairy & Bakery",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Grocery",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Ayurvedic",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
];
