import React, {  useState } from "react";
import { RxCross1 } from "react-icons/rx";
import Button from "../../ui/elements/Button";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";

const ContactModal = ({ show, onClose, children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState(location.state?.data || null);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        "https://api.zippyglide.com/api/orders/get-by-id",
        { id: inputValue }
      );
      if (response.data) {
        setData(response.data);
        navigate("/detail", {replace: true,  state: { data: response.data } });
        onClose();
      } else {
        setShowMessage(true);
        setInputValue("");
        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      setInputValue("");
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (data) {
  //     navigate(location.pathname, { replace: true, state: {} });
  //   }
  // }, [data, navigate, location.pathname]);

  if (!show) {
    return null;
  }
  return (
    <div>
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal_header">
            <h4>Track Shipment</h4>
            <div className="close-button" onClick={onClose}>
              <RxCross1 />
            </div>
          </div>
          <form onSubmit={handleSubmit} className="contact_form">
            <div className="mybtn detailbtn">
              <input
                type="text"
                placeholder="Track Shipment"
                className="form-control"
                value={inputValue}
                onChange={handleInputChange}
              />
              <Button className="primarybtn" onClick={()=>handleSubmit()}>
                {loading ? (
                  <div className="loader"></div>
                ) : (
                  <IoIosSearch size={26} />
                )}
              </Button>
            </div>
            {showMessage && (
              <div className="message pt-1">Shipment Details not found!</div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;
