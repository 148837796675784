import React from "react";
import {
  IoCallOutline,
  IoLocationOutline,
  IoMailOutline,
} from "react-icons/io5";

const ContactUs = () => {
  const contactData = [
    {
      icon: <IoLocationOutline size={25} />,
      title: "Location",
      description:
        "001, SENAPATI, C/O NG ROMI, TAPHOU NGAIHANG, SENAPATI,MANIPUR, 795106",
      url: "https://maps.app.goo.gl/xh5cGgfEzsjbiG576",
    },

    {
      icon: <IoCallOutline size={25} />,
      title: "Contact No.",
      description: "(+91) 7485007406",
      url: "tel:+917485007406",
    },
    {
      icon: <IoMailOutline size={25} />,
      title: "Email Address",
      description: "malistarparvesh@gmail.com",
      url: "mailto:malistarparvesh@gmail.com",
    },
  ];
  return (
    <>
      <div className="container">
        <div className="comman_space">
          <div className="row justify-content-center my-2 pt-5">
            <div className="col-lg-6 col-md-10 text-center pt-3">
              <div className="commantext">
                <h4>
                  <span>Contact</span> Us
                </h4>
                <p>
                  we value our customers and are here to assist you with any
                  questions, concerns, or feedback you may have. Please feel
                  free to reach out to us through any of the following channels.
                </p>
              </div>
            </div>
          </div>

          <div className="source_box">
            {contactData.map((item, index) => (
              <div className="source_boxsub" key={index}>
                <div className="source_icon">{item.icon}</div>
                <div>
                  <h4>{item.title}</h4>
                  <p>
                    <a href={item?.url}>{item.description}</a>
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="text-center mt-4">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3716.4047497852257!2d94.00843262547782!3d25.25905727919216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3748bf142a5e47f3%3A0xf68b2adce2d21ca7!2sTaphou%20Ngaihang%2C%20Manipur%20795106!5e1!3m2!1sen!2sin!4v1722847571551!5m2!1sen!2sin"
              className="iframe"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              title="map"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
