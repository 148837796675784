import React from "react";
import { Icon } from "../../ui";

const AboutUs = () => {
  const aboutDetail = [
    {
      icon: "aboutIcon1",
      title: "Eco-Friendly Practices",
      discription:
        "We are committed to reducing our environmental impact. Through sustainable packaging and energy-efficient delivery methods, we strive to make our operations greener and more responsible.",
    },
    {
      icon: "aboutIcon2",
      title: "Expert Logistics Team",
      discription:
        "Our team of logistics experts ensures that every shipment is handled with care and precision. With years of experience, we navigate the complexities of shipping to deliver your package safely and efficiently.",
    },
  ];
  return (
    <>
      <div className="aboutbg">
        <div className="container">
          <div className="comman_space">
            <div className="row align-items-center justify-content-center pt-5">
              <div className="col-lg-7 order-lg-1 order-2 pt-5 text-center">
                <div className="commantext">
                  <h4>About Us</h4>
                </div>
                <div className="commanOther">
                  <p>
                    We ANTHANGMI TECHNOLOGY PRIVATE LIMITED have emerged as a
                    prominent entity rendering credible courier services in
                    india. Our firm understands and fulfills the requirements of
                    our customer by providing the highest level of reliability
                    and services to our customers. Experts in our firm are
                    constantly providing intercity and domestic services to our
                    customer. We provide Courier Service In Gujarat,
                    Maharashtra, Banglore, Chennai, Delhi, Kolkatta, Rajasthan
                    etc...
                  </p>
                </div>
                <Icon name="aboutusPage" className="img-fluid about_pageimg" />
              </div>
            </div>
          </div>
          <div className="about_detailmain">
            {aboutDetail?.map((item, index) => {
              return (
                <div className="about_detail" key={index}>
                  <Icon name={item?.icon} className="img-fluid" />
                  <h4>{item?.title}</h4>
                  <p>{item?.discription}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
