import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Detail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState(location.state?.data || null);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = () => {
    // navigate("/");
    setData(null);
    // return;
  };

  useEffect(() => {
    if (!data) {
      navigate("/");
      setData(null);
      return;
    }
  }, [data, navigate, location.pathname]);

  const details = [
    { label: "Site Name", value: data?.data?.site_name },
    { label: "Status", value: data?.data?.status },
    { label: "Current Place", value: data?.data?.current_place },
    { label: "Updated At", value: data?.data?.updated_at },
    { label: "Created At", value: data?.data?.created_at },
  ];

  return (
    <div className="container">
      <div className="comman_space mt-5">
        <div className="row justify-content-center my-2">
          <div className="col-lg-6 col-md-10 text-center">
            <div className="commantext">
              <h4>
                Your <span>Shipment</span> Details
              </h4>
              <p>
                Effortlessly monitor your shipment's journey in real-time and
                stay updated on its status every step of the way.
              </p>
            </div>
          </div>
        </div>
        <div className="py-2 detailmain">
          {data ? (
            <div>
              <div className="detailcard my-3">
                <h6 className="detail_heading">Delivery Detail</h6>
                <div className="tablemain">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Tracking ID</th>
                        <th>Date/Time</th>
                        <th>State</th>
                        <th>City </th>
                        <th>Email</th>
                        <th>Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{data?.data?.tracking_id}</td>
                        <td>{data?.data?.delivery_date}</td>
                        <td>{data?.data?.delivery_state}</td>
                        <td>{data?.data?.delivery_city}</td>
                        <td>{data?.data?.delivery_person_contact_email}</td>
                        <td>{data?.data?.delivery_person_contact_no}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="detailcard">
                <h6 className="detail_heading">Other Detail</h6>
                <div className="order_main">
                  {details.map((detail, index) => (
                    <div key={index} className="order_sub">
                      <h6>{detail.label}</h6>
                      <p>{detail.value}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Detail;
