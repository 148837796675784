import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon } from "../../ui";
import ContactModal from "../home/ContactModal";

const Header = () => {
  const [isNavbarOpen, setNavbarOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // useEffect(() => {
  //   const handleResize = () => {
  //     setWindowWidth(window.innerWidth);
  //   };
  //   handleResize();
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  const toggleNavbar = () => {
    setNavbarOpen(!isNavbarOpen);
  };

  const closeNavbar = () => {
    setNavbarOpen(false);
  };
  return (
    <>
      <div
        className={`nav-bg fixed-top scale-up-hor-center  ${
          scrollPosition > 0 ? "darkbg nav-bg-shadow" : "bg-transparent pt-3"
        }  `}
      >
        <div className="container">
          <nav
            className={`navbar navbar-expand-lg navbar-light self-nav darkbg ${
              scrollPosition <= 0 ? "px-2" : ""
            } `}
          >
            <div className="container-fluid justify-content-between ">
              <div>
                <p
                  className="navbar-brand"
                  onClick={() => {
                    closeNavbar();
                    navigate("/");
                  }}
                >
                  <Icon name="Logo" className="" />
                </p>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={toggleNavbar}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className={`collapse navbar-collapse justify-content-end ${
                  isNavbarOpen ? "show" : ""
                }`}
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mb-2 mb-lg-0">
                  <li
                    className={`nav-item ${
                      location.pathname === "/" ? "active" : ""
                    }`}
                  >
                    <p
                      className={`nav-link`}
                      aria-current="page"
                      onClick={() => {
                        closeNavbar();
                        navigate("/");
                      }}
                    >
                      Home
                    </p>
                  </li>
                  <li
                    className={`nav-item ${
                      location.pathname === "/about-us" ? "active" : ""
                    }`}
                  >
                    <p
                      className={`nav-link`}
                      onClick={() => {
                        navigate("/about-us");
                        closeNavbar();
                      }}
                    >
                      About Us
                    </p>
                  </li>
                  <li
                    className={`nav-item ${
                      location.pathname === "/contact-us" ? "active" : ""
                    }`}
                  >
                    <p
                      className={`nav-link`}
                      onClick={() => {
                        navigate("/contact-us");
                        closeNavbar();
                      }}
                    >
                      Contact Us
                    </p>
                  </li>
                  <li
                    className={`nav-item ${
                      location.pathname === "/detail" ? "active" : ""
                    }`}
                  >
                    <p
                      className={`nav-link`}
                      onClick={() => {
                        // navigate("/detail");
                        closeNavbar();
                        setIsModalOpen(true);
                      }}
                    >
                      Track Courier
                    </p>
                  </li>
                  {/* <li
                    className={`nav-item ${
                      activeSection === "usp" ? "active" : ""
                    }`}
                  >
                    <p
                      className="nav-link"
                      onClick={() => {
                        navigate("/pickup");
                        closeNavbar();
                      }}
                    >
                      Pickup Request
                    </p>
                  </li> */}
                </ul>
                {/* {windowWidth < 991 ? (
                  <form className="navbtn">
                    <Button
                      className="secondarybtn"
                      onClick={() => {
                        navigate("/contact-us");
                        closeNavbar();
                      }}
                    >
                      Contact Us
                    </Button>
                  </form>
                ) : (
                  ""
                )} */}
              </div>
              {/* {windowWidth > 991 ? (
                <form className="mybtn">
                  <Button
                    className="secondarybtn"
                    onClick={() => navigate("/contact-us")}
                  >
                    Contact Us
                  </Button>
                </form>
              ) : (
                ""
              )} */}
            </div>
          </nav>
        </div>
      </div>

      <ContactModal show={isModalOpen} onClose={handleModalClose} />
    </>
  );
};

export default Header;
